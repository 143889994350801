<script>
import {
    BaseButton,
    IconDoc,
    IconGroupLight,
    IconTeacherLight,
    IconCalendarCheckLight,
    IconClassBookLight,
    IconCoinEuroLight,
    IconStar,
    IlluMedal,
    IlluNumber1,
} from 'aca-design'

import AgencyAddress from '@/components/AgencyAddress.vue'
import IconBubbles from '@/components/IconBubbles.vue'
import IconCheckCircle from '@/components/IconCheckCircle.vue'
import IconStudentComputer from '@/components/IconStudentComputer.vue'

export default {
    name: 'CourseDetails',

    props: {
        group: {
            type: Array,
            required: true,
        },

        course: {
            type: Object,
            required: true,
        },

        details: {
            type: Object,
            required: true,
        },

        isStage: {
            type: Boolean,
            default: true
        },
    },

    components: {
        AgencyAddress,
        IconDoc,
        IconGroupLight,
        IconTeacherLight,
        IconCalendarCheckLight,
        IconClassBookLight,
        IconCoinEuroLight,
        IconBubbles,
        IconCheckCircle,
        IconStudentComputer,
        IconStar,
        IlluMedal,
        IlluNumber1,
        BaseButton
    },

    data () {
        return {
            agency: null
        }
    },

    computed: {

    },

    methods: {

    },
}
</script>

<template>
    <div class="course-details flex-col lg:flex-row flex lg:space-x-5 pt-0 pb-4 px-5">
        <div class="basis-full lg:basis-3/5 mb-4 lg:mr-8 lg:-mt-14">
            <h2 class="font-toronto-gothic lg:text-5xl text-3xl pb-5 lg:pb-4">{{ details['Nom du produit'] }}</h2>
            <div class="pb-5 md:text-lg text-sm font-semibold">
                <div class="flex items-center mb-2">
                    <IconGroupLight class="mr-4 self-start mt-1 shrink-0"/>
                    <div>
                        {{ details['Caractéristiques Format'] }}
                    </div>
                </div>
                <div class="flex items-center mb-2">
                    <IconCalendarCheckLight  class="mr-4 self-start mt-1 shrink-0"/>
                    <div>
                        Stage de {{ course.nbHeure }}
                    </div>
                </div>
                <div class="flex items-center mb-2">
                    <IconCoinEuroLight class="mr-4 self-start mt-1 shrink-0" />
                    <div>
                        {{ course.tarif }}€
                    </div>
                </div>
            </div>

            <h3 class="pb-1 font-bold text-2xl flex items-center" style="color: var(--AkBlueNote);">
                <IlluMedal class="mr-1" />Objectifs
            </h3>

            <div class="pb-5 lg:pb-10 leading-8 lg:leading-10 ml-3" v-html="details['Objectifs']"></div>

            <h3 class="pb-5 font-bold text-2xl flex items-center" style="color: var(--AkBlueNote);">
                <IlluNumber1 class="mr-31" />Programme
            </h3>
            <div class="bg-white rounded-3xl shadow-lg p-5 lg:p-10">
                <template v-for="i in 5">
                    <template v-if="details['Programme séance ' + i]">
                        <div :key="i">
                            <div class="pb-2 font-bold note">{{ details['Titre Séance / Objet ' + i] }}</div>
                            <div class="pb-6 whitespace-pre-wrap">{{ details['Programme séance ' + i] }}</div>
                        </div>
                    </template>
                </template>
            </div>
            <div class="pt-5 flex justify-center lg:block">
                <BaseButton
                  class="base-button-solid text-sm font-semibold"
                  @click="$emit('bookCourse', group, course)">
                    <span>Choisir ce stage</span>
                </BaseButton>
            </div>
        </div>
        <div  class="basis-full lg:basis-2/5 lg:ml-14">
            <div class="rounded-2xl p-5 lg:p-8 mb-5 shadow-lg" style="background: var(--AkMinorYellow)">
                <div class="font-bold text-2xl mb-6 flex items-center"><IconStar style="width: 35px; color: var(--AkMainOrange)" class="mr-3"/> Les + de ce stage</div>
                <div class="mb-3 flex">
                    <IconTeacherLight class="shrink-0 mr-3 self-start mt-1" />
                    <div>
                        Un <strong>conseiller pédagogique</strong> à votre écoute avant, pendant et après le stage.
                    </div>
                </div>
                <div class="mb-3 flex">
                    <IconDoc class="shrink-0 mr-3 self-start mt-1" />
                    <div>
                        Un <strong>bilan en fin de stage</strong> pour un véritable suivi des progrès.
                    </div>
                </div>
                <div class="mb-3 flex">
                    <IconBubbles class="shrink-0 mr-3 self-start mt-1" />
                    <div>
                        L’accès à une <strong>plateforme d’entraînement en ligne</strong> (fiches de révision, vidéos de cours, exercices, quiz...).
                    </div>
                </div>
            </div>
            <AgencyAddress v-if="course.agenceId && !course.enLigne"
              class="shadow-lg"
              @bookCourse="$emit('bookCourse', group, course)"
              :isStage="isStage"
              :agencyId="course.agenceId"/>

            <div v-if="course.enLigne" class="rounded-2xl p-5 lg:p-8 mb-5 shadow-lg" style="background: var(--colorMinor)">
                <div class="font-bold text-2xl mb-6 flex items-center">
                    <IconStudentComputer style="width: 35px; color: var(--AkMainBlue)" class="mr-3"/>
                    Les stages en ligne Acadomia
                </div>
                <div class="mb-3 flex">
                    <IconClassBookLight class="shrink-0 mr-3 self-start mt-1" />
                    <div>
                        Une véritable <strong>salle de cours virtuelle</strong> spécialement créée pour nos élèves.
                    </div>
                </div>
                <div class="mb-3 flex">
                    <IconGroupLight class="shrink-0 mr-3 self-start mt-1" />
                    <div>
                        Une <strong>pédagogie interactive et participative</strong> permettant de maximiser l’attention, l’implication et la compréhension de l’élève.
                    </div>
                </div>
                <div class="text-lg font-bold mb-5 mt-8">
                    Pour un accompagnement optimal, assurez-vous d’avoir :
                </div>
                <div class="mb-3 flex">
                    <IconCheckCircle style="color: var(--AkBlueNote);" class="shrink-0 mr-3 self-start mt-1" />
                    <div>
                        Une <strong>connexion internet stable</strong>
                    </div>
                </div>
                <div class="mb-3 flex">
                    <IconCheckCircle style="color: var(--AkBlueNote);" class="shrink-0 mr-3 self-start mt-1" />
                    <div>
                        Un ordinateur doté d’un <strong>micro et d’une caméra</strong>
                    </div>
                </div>
                <div class="mb-3 flex">
                    <IconCheckCircle style="color: var(--AkBlueNote);" class="shrink-0 mr-3 self-start mt-1" />
                    <div>
                        Un <strong>endroit calme</strong> où s’installer
                    </div>
                </div>
                <div class="pt-5 flex justify-center lg:block">
                    <BaseButton
                      class="base-button-solid text-sm font-semibold"
                      @click="$emit('bookCourse', group, course)">
                        <span>Choisir ce stage</span>
                    </BaseButton>
                </div>
            </div>

        </div>
    </div>
</template>

<style lang="stylus">
.course-details
    .base-illu .primary-color
        fill var(--AkBlueNote);
</style>
