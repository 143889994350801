<script>
import {
    BaseButton,
    IconArrowLeft,
} from 'aca-design'

import ContactForm from '@/components/ContactForm.vue'
import BaseLoader from '@/components/BaseLoader.vue'
import IconBinCircle from '@/components/IconBinCircle.vue'

function addOrRemove(array, value) {
    var index = array.indexOf(value)

    if (index === -1) {
        array.push(value)
    } else {
        array.splice(index, 1)
    }
}

export default {
    name: 'BasketScreen',

    props: {
        selectedCourses: {
            type: Array,
        },

        getDetailById: {
            type: Function,
        },

        landing: {
            type: Object,
            required: true
        },

        model: {
            type: Object,
            default() {
                return {}
            }
        },

        isStage: {
            type: Boolean,
            default: true
        },

        zipCodeFilter: {
            type: [Object, String]
        }
    },

    components: {
        ContactForm,
        BaseButton,
        BaseLoader,
        IconArrowLeft,
        IconBinCircle,
    },

    watch: {

    },

    data () {
        return {
            expandedCourseIds: [],
            contactFormCustomComponentLoaded: false,
            contactFormLoading: true,
        }
    },

    computed: {
        contactFormPrefilledModel() {
            const courses = this.selectedCourses.map(({course}) => course)

            const enLigne = [...new Set(courses.map((course) => course.enLigne))]

            const levelMax = courses.reduce((prev, current) => (prev.niveauId > current.niveauId) ? prev : current)

            const propPrefilledModel = this.model
            const prefilledModel = {
                ...propPrefilledModel,
                subject: courses.map((course) => `${course.matiereLib}`).join('; '),
                'level-a': { name: levelMax.niveauLib, value: levelMax.niveauLib },
                goal: courses.map((course) => {
                    const duration = course.heureDebut === 'Journée' ? 'toute la journée' : `de ${course.heureDebut} à ${course.heureFin}`
                    return `${course.produitdesc}, ${course.niveauLib}, ${course.dateDebut} au ${course.dateFin}, ${duration}, ${course.enLigne ? 'En ligne' : 'Centre ' + course.agenceLib} `
                }).join('; '),
                agencyId: null,
                zipcode: this.zipCodeFilter,
                enLigne: enLigne
            }
            return JSON.stringify(prefilledModel)
        },
    },

    async mounted() {
        this.lazyload()
        await customElements.whenDefined('landing-form')
        this.contactFormCustomComponentLoaded = true
    },

    methods: {
        lazyload() {
            var scriptTag = document.createElement('script')
            scriptTag.src = `${process.env.VUE_APP_FORM_COMPONENT_URL}`
            scriptTag.type = 'text/javascript'
            scriptTag.async = true
            var headTag = document.getElementsByTagName('head')[0]
            headTag.appendChild(scriptTag)
        },

        handleExpandCourseClick(id) {
            addOrRemove(this.expandedCourseIds, id)
        },

        handleVariantChange(course, group) {
            this.$emit('selectedVariantChange', course, group)
        },

        handleModelChanged({ model }) {
            this.$emit('modelChanged', model)
        }
    },
}
</script>

<template>
<div class="basket-screen pb-5">
    <!-- Needed unless what the bin icon is wrongly bold, idk why -->
    <IconBinCircle style="width:0; height: 0;" />

    <div
      class="flex items-center cursor-pointer mb-6 mt-6 w-full lg:w-auto"
      @click="$emit('addCourseClick')">
        <div
          class="inline-flex rounded-full bg-black text-white justify-center items-center mr-4 previous-btn">
            <IconArrowLeft class="previous-btn-inner" />
        </div>
        <span class="font-bold">Revenir à la recherche</span>
    </div>
    <div class="flex lg:space-x-8 flex-col lg:flex-row">
        <div class="basis-full lg:basis-1/2">
            <div class="lg:bg-neutral-100 rounded-xl lg:shadow-md p-6">
                <div class="text-3xl font-bold mb-6">
                    Récapitulatif de votre sélection
                </div>
                <div v-for="{ group, course } in selectedCourses"
                  :key="course.coursId"
                  class="flex border-solid border-0 border-b py-5 border-gray-300">

                    <div class="grow">
                        <div class="font-bold mb-2">
                           {{ course.produitDesc }} |
                           <template v-if="course.enLigne">
                               En ligne
                           </template>
                           <template v-else>
                               Agence {{ course.agenceLib }}
                           </template>
                        </div>

                        <div v-if="isStage" class="cursor-pointer font-normal text-sm animated-underline-link " @click="$emit('courseProgramClick', group, course)">
                            Voir le détail du stage
                        </div>
                    </div>
                    <div class="font-bold mr-8">
                        <template v-if="isStage">
                            {{ course.tarif }}&nbsp;€
                        </template>
                    </div>
                    <div class="shrink-0" >
                        <IconBinCircle class="shrink-0 cursor-pointer" @click.native="$emit('removeCourseClick', group[0].id)"/>
                    </div>
                </div>
                <div class="mt-5 flex justify-between text-2xl font-bold">
                    <div>
                        Total
                    </div>
                    <div>
                        {{ selectedCourses.map(({ course }) => course.tarif).reduce((a, b) => a + b, 0) }}€<template v-if="!isStage">/mois</template>
                    </div>
                </div>
                <div class="flex justify-center">
                    <BaseButton
                      class="base-button-color mt-5 font-bold px-6 inline-flex self-start"
                      @click="$emit('addCourseClick')">
                        + Ajouter un autre <template v-if="isStage">stage</template><template v-else>cours</template>
                    </BaseButton>
                </div>
            </div>


        </div>
        <div class="basis-full lg:basis-1/2">
            <div class="lg:bg-white rounded-xl lg:shadow-md lg:p-6">
                <template v-if="contactFormCustomComponentLoaded">
                    <div v-if="contactFormLoading" class="flex justify-center items-center py-5">
                        <BaseLoader class="w-16"/>
                    </div>
                    <ContactForm :key="contactFormPrefilledModel"
                      @loaded="contactFormLoading = false"
                      @success="$emit('success')"
                      @modelChanged="handleModelChanged"
                      :class="{ hidden: contactFormLoading }"
                      :prefilled-model="contactFormPrefilledModel"
                      :landing-name="landing.contact_form.name"></ContactForm>
                </template>
            </div>
        </div>
    </div>
</div>

</template>

<style lang="stylus">
.basket-screen
    .circle-button
        width 35px
        height 35px
        border-radius 100%
        background var(--AkGreyscaleBlack)
        display flex
        align-items center
        justify-content center
        color #FFF
</style>