var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-zipcode base-input",class:Object.assign({}, _vm.$attrs.class,
    {'has-value': _vm.displayableValue && _vm.displayableValue.length,
    'is-dropdown-value': typeof this.value === 'object',
    'is-loading': _vm.loading,
    'is-open': _vm.open,
    'focused': _vm.focused,
    'has-results': _vm.results && _vm.results.length > 0,
    'has-many-results': _vm.results && _vm.results.length > 1,
    'not-touch-device': !_vm.isTouchDevice})},[_c('label',{attrs:{"for":_vm.uid}},[_c('div',{staticClass:"label-text"},[_vm._t("label")],2)]),_c('div',{ref:"multilist",staticClass:"multilist"},[_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"before"},[_vm._t("before")],2),_c('input',_vm._g(_vm._b({staticClass:"multilist-input",attrs:{"id":_vm.uid,"type":"text"},domProps:{"value":_vm.displayableValue},on:{"input":function($event){return _vm.handleInput($event)},"focus":function($event){return _vm.handleFocus($event)},"blur":function($event){return _vm.handleBlur($event)}}},'input',_vm.$attrs,false),_vm.$listeners)),_c('div',{staticClass:"after"},[_vm._t("after")],2)]),(_vm.open)?_c('div',{staticClass:"multilist-results"},_vm._l((_vm.results),function(result){return _c('div',{key:result.cpId + '-' + result.communeId,staticClass:"multilist-result",on:{"mousedown":function($event){return _vm.handleResultClick(result)}}},[_vm._v(" "+_vm._s(result.cpCode)+" - "+_vm._s(result.communeLib)+" ")])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }