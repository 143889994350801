<script>

import {
    BaseCheckbox,
    IconAddress,
    IconClock,
    IconCross,
    IconCalendarCheckLight,
    IconTeacherLight,
} from 'aca-design'

import PopperJS from '@/components/PopperJS.vue'
import AgencyAddress from '@/components/AgencyAddress.vue'
import IconCheckCircle from '@/components/IconCheckCircle.vue'

export default {
    name: 'CourseCard',

    components: {
        PopperJS,
        IconAddress,
        IconClock,
        IconCross,
        IconCheckCircle,
        IconCalendarCheckLight,
        IconTeacherLight,
        AgencyAddress,
        BaseCheckbox,
    },

    props: {
        group: {
            type: Array,
            required: true,
        },

        collapsed: {
            type: Boolean,
            default: false
        },

        checkedVariantId: {
            type: String
        },

        detail: {
            type: Object
        },

        isStage: {
            type: Boolean,
            default: true
        },
    },

    data () {
        return {
            showAddress: false,
            isMobile: window.matchMedia('(max-width: 800px)').matches
        }
    },

    computed: {
        course() {
            return this.group[0]
        }
    },

    methods: {
        readableLevel(level) {
            if (level === '1ère') {
                return '1re'
            }
            if (level === '2nd') {
                return '2de'
            }
            if (level === '3ème') {
                return '3e'
            }
            if (level === '4ème') {
                return '4e'
            }
            if (level === '5ème') {
                return '5e'
            }
            if (level === '6ème') {
                return '6e'
            }

            return level
        },

        readableDate(dateString) {
          const [day, month, year] = dateString.split('/')
          const date = new Date(year, month - 1, day)
          const options = { month: 'long', day: 'numeric' }
          return date.toLocaleDateString('fr-FR', options)
        }
    },
}
</script>

<template>
    <div
      class="course-card shrink-0 grow-0 bg-white rounded-2xl inline-flex flex-col relative">
        <div class="p-5 pb-0">
            <div class="lg:mb-6 mb-5 flex lg:items-center flex-col lg:flex-row" >
                <span v-if="course.enLigne" class="tag-ligne w-max">En ligne</span>
                <span v-else class="tag w-max">En centre</span>
                <PopperJS v-if="!course.enLigne"
                    ref="popper"
                    trigger="clickToOpen"
                    :append-to-body="true"
                    @show="showAddress = true"
                    @hide="showAddress = false"
                    :options="{
                      placement: 'right',
                      strategy: 'fixed',
                      modifiers: {
                        offset: {
                            offset: isMobile ? '0, 0' : '0, 10px',
                        },
                        preventOverflow: {
                            boundariesElement: 'viewport'
                        }
                      }
                    }">
                    <div :style="`max-width: ${isMobile ? 'none': '350px'};`" class="z-10 w-full">
                        <AgencyAddress v-if="showAddress"
                          @loading="$refs.popper.updatePopper()"
                          @loaded="$refs.popper.updatePopper()"
                          @bookCourse="$emit('bookCourse', group, course)"
                          class="lg:p-5 shadow-2xl"
                          :agencyId="course.agenceId"
                          :showMap="false"
                          :isStage="isStage"
                        >
                            <template #top-right>
                                <IconCross
                                  class="cursor-pointer"
                                  @click.native="$refs.popper.doClose()" />
                            </template>
                        </AgencyAddress>
                    </div>

                    <span slot="reference" class="cursor-pointer flex font-bold text-lg lg:mt-0 mt-5">
                        <IconAddress class="lg:ml-6 mr-1"/> {{course.agenceLib}}
                    </span>
                </PopperJS>
            </div>
            <div class="absolute top-5 right-3">
                <slot name="top-right"></slot>
            </div>

            <h4 class="font-semibold text-xl lg:text-2xl pr-2">{{ course.produitDesc }}</h4>
            <div v-if="!isStage" class="lg:my-4 my-5 flex font-bold">
                <template >
                    <IconTeacherLight style="color: var(--color3);" class="self-start mt-0 mr-2 shrink-0" /> Élèves niveau {{course.niveauLib}}
                </template>
            </div>
            <div class="lg:my-4 my-5 flex font-bold">
                <template v-if="!isStage">
                    <IconCalendarCheckLight style="color: var(--color3);" class="self-start mt-1 mr-2 shrink-0" /> Toute l'année (hors vacances scolaires)
                </template>
                <template v-else-if="course.dateDebut === course.dateFin">
                    <IconCalendarCheckLight style="color: var(--color3);" class="self-start mt-1 mr-2 shrink-0" /> Le {{ readableDate(course.dateDebut) }}
                </template>
                <template v-else>
                    <IconCalendarCheckLight style="color: var(--color3);" class="self-start mt-1 mr-2 shrink-0" /> Du {{ readableDate(course.dateDebut) }} au {{ readableDate(course.dateFin) }}
                </template>
            </div>
            <template v-if="group.length > 1">
                <div class="lg:mt-4 mt-5 flex items-center font-bold">
                    <IconClock class="mr-2 shrink-0" style="color: var(--color3); align-self: flex-start; margin-top: 2px; "/> Choisissez votre créneau de préférence
                </div>
                <div class="lg:mt-2 mt-3 lg:mb-4 mb-5 pl-6">
                    <div v-for="item in group" :key="item.id" class="flex items-center mb-2">
                        <BaseCheckbox class="base-checkbox-round" :class="{ 'font-bold': checkedVariantId === item.id }"
                          :checked="checkedVariantId === item.id"
                          @change="$emit('variantChange', item, group)">
                            <div slot="label" class="">
                                <template v-if="item.heureDebut === 'Journée'">
                                    Journée
                                </template>
                                <template v-else>
                                    <template v-if="item.jourDebut"> {{ item.jourDebut }}</template>
                                    {{ item.heureDebut }}&nbsp;-&nbsp;{{ item.heureFin }}
                                </template>
                            </div>
                            <div slot="indicator" class="indicator-dot"></div>
                        </BaseCheckbox>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="lg:mt-4 mt-5 mb-3 flex items-center">
                    <IconClock class="mr-2 shrink-0" style="color: var(--color3); align-self: flex-start; margin-top: 2px; "/>
                    <template v-if="group[0].heureDebut === 'Journée'">
                        Journée
                    </template>
                    <template v-else>
                        <template v-if="group[0].jourDebut"> {{ group[0].jourDebut }}</template>
                        {{ group[0].heureDebut }}&nbsp;-&nbsp;{{ group[0].heureFin }}
                    </template>
                </div>
            </template>

            <div v-if="detail" class="pt-6">
                <div class="p-1 bg-black text-white inline-block font-semibold mb-3">Informations pratiques</div>
                <div class=" mb-2 flex" v-if="detail['Caractéristiques Qui']">
                    <IconCheckCircle class="inline-block mt-1 mr-2 shrink-0" />Élèves de niveau {{ course.niveauLib }}
                </div>
                <div class=" mb-2 flex" v-if="detail['Caractéristiques Format']">
                    <IconCheckCircle class="inline-block mt-1 mr-2 shrink-0" />{{ detail['Caractéristiques Format'] }}
                </div>
                <div class=" mb-2 flex" v-if="detail['Caractéristiques Comment']">
                    <IconCheckCircle class="inline-block mt-1 mr-2 shrink-0" />Stage de {{ group[0].nbHeure }}
                </div>
            </div>
            <div v-else-if="!isStage" class="pt-6">
                <div class="p-1 bg-black text-white inline-block font-semibold mb-3">Informations pratiques</div>
                <div class=" mb-2 flex" >
                    <IconCheckCircle class="inline-block mt-1 mr-2 shrink-0" />En petit groupe de 3 à 8 élèves maximum
                </div>
                <div class=" mb-2 flex" >
                    <IconCheckCircle class="inline-block mt-1 mr-2 shrink-0" />Un accompagnement individualisé pour aider chacun à apprendre et progresser à son rythme
                </div>
                <div class=" mb-2 flex">
                    <IconCheckCircle class="inline-block mt-1 mr-2 shrink-0" />Accès à une plateforme d’entraînement en ligne (fiches de révision, vidéos de cours, exercices, quiz...)
                </div>
            </div>
        </div>
        <div class="grow"></div>
        <div class="bottom-slot mt-2 rounded-b-2xl">
            <slot name="bottom" :checkedVariantId="checkedVariantId" :course="course" :detail="detail"></slot>
        </div>
    </div>
</template>

<style lang="stylus">
    .bottom-slot
        background var(--AkMainBlue40)
</style>
