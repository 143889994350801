export const typesRepetition = {
    bis: 'Bis',
    ter: 'Ter',
}

export const typesRoute = {
    avenue: 'AV',
    rue: 'RUE',
    allee: 'ALL',
    boulevard: 'BD',
    chemin: 'CHEM',
    cite: 'CITE',
    cours: 'COURS',
    galerie: 'GALERIE',
    impasse: 'IMP',
    lieu: 'LD',
    lotissement: 'LOT',
    passage: 'PAS',
    place: 'PL',
    pont: 'PONT',
    quai: 'QUAI',
    residence: 'RES',
    'rond-point': 'RPT',
    route: 'RTE',
    square: 'SQ',
    villa: 'VILLA',
    vla: 'VILLA',
}
