import { render, staticRenderFns } from "./IconProgression.vue?vue&type=template&id=f6f970d0&"
import script from "./IconProgression.vue?vue&type=script&lang=js&"
export * from "./IconProgression.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports