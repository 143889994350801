<script>
export default {

    name: 'ContactForm',

    props: {
        prefilledModel: {
            type: String,
        },

        landingName: {
            type: String,
            required: true
        }
    },

    data () {
        return {

        }
    },

    computed: {
    },
    mounted() {
        this.$nextTick(() => {
            const formElement = this.$refs['contactForm']
            formElement.addEventListener('message', this.handleFormMessage)
        })
    },

    beforeDestroy() {
        const formElement = this.$refs['contactForm']
        formElement.removeEventListener('message', this.handleFormMessage)
    },

    methods: {
        handleFormMessage(event)  {
            const detail = event.detail[0]
            if (detail.eventName === 'ACA_FORM_LOADED') {
                this.$emit('loaded')
            }
            if (detail.eventName === 'ACA_FORM_POST_SUCCESS') {
                this.$emit('success')
            }
            if (detail.eventName === 'ACA_FORM_MODEL_CHANGED') {
                this.$emit('modelChanged', detail.payload)
            }
        },
    },

}
</script>

<template>
    <landing-form
      ref="contactForm"
      :prefilled-model="prefilledModel"
      :landing-name="landingName"></landing-form>
</template>